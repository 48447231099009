@import "../../../assets/scss/partials/_colors";

.progress {
  position: relative;
  overflow: visible!important;
  overflow-x: hidden;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.14);
}

.progressBar {
  z-index: 2;
  position: relative;
  overflow: visible!important;
  overflow-x: hidden;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  transition: width 2.4s ease-out!important;
  box-shadow: 0 0 0 $primary;
}

.progressText {
  color: $gray-900;
  position: absolute;
  left: 16px;
  bottom: 0;
  top: 0;
  margin: auto;
  height: fit-content;
  z-index: 20;
  -webkit-background-clip: text!important;
  -webkit-text-fill-color: transparent;
  transition: all 400ms ease !important;
}

.badge {
  top: -3rem;
  position: absolute;
  z-index: 50;
  left: 100%;
  transition: transform 1.6s ease;
}

.Pointer {
  position: absolute;
  left: 100%;
  right: -20px;
  transform-origin: 50% 100%;
  clip-path: polygon(100% 0%, 0% 0%, 50% 100%);
  margin-left: -10px;
  transition: transform 1.6s ease;
  background: $secondary;
  width: 20px;
  height: 30px;
  top: -30px;
}

.progressLabels {
  position: relative;
}

.thresholdLabel {
  display: inline-block;
  position: absolute;
  transform: translate(-50%, 0.25rem);
}

.threshold1Label {
  @extend .thresholdLabel;
  left: 45%;
}

.threshold2Label {
  @extend .thresholdLabel;
  left: 90%;
}

.thresholdLine {
  border-left: 2px solid $gray-500;
  height: 1.75rem;
  position: absolute;
  z-index: 10;
  transform: translateY(-1.5rem);
}
